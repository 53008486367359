<template>
  <tr v-if="region && region.market_area_id == market_area_id">
    <td><router-link :to="{ name: 'RegionShow', params: { id: region.id } }">{{ region.name }}</router-link></td>
    <td>{{ region.region_code }}</td>
  </tr>
</template>

<script>
export default {
  name: 'RegionsRow',
  props: {
    region: Object,
    market_area_id: Number,
  },
};
</script>

<style scoped>

</style>
