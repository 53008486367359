<template>
  <div>
    <div v-if="market_area">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Market Area: <small>{{ market_area.name }}</small></h4>
        </div>
      </div>
      <div class="card">
        <div class="rainbow-background"></div>
        <div class="table-responsive">
          <table class="card-table table">
            <tbody class="text-left">
            <tr>
              <th scope="col">ID</th>
              <td>{{ market_area.id }}</td>
            </tr>
            <tr>
              <th scope="col">Title</th>
              <td>{{ market_area.name }}</td>
            </tr>
            <tr>
              <th scope="col">Created At</th>
              <td>{{ new Date(market_area.created_at).toDateString() }}, {{ new Date(market_area.created_at).toLocaleTimeString() }}</td>
            </tr>
            <tr>
              <th scope="col">Updated At</th>
              <td>{{ new Date(market_area.updated_at).toDateString() }}, {{ new Date(market_area.created_at).toLocaleTimeString() }}</td>
            </tr>
            <tr>
              <th scope="col">Is Archived?</th>
              <td>{{ market_area.is_archived }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="btn-group float-right">
            <router-link :to="{ name: 'MarketAreaIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
            <router-link :to="{ name: 'MarketAreaEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
            <button v-on:click="$refs.market_areaDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
          </div>
        </div>
      </div>

      <regions :market_area_id="market_area.id"></regions>

      <!-- Modal Component -->
      <b-modal ref="market_areaDeleteModal" header-bg-variant="danger" header-text-variant="light" class="market_areaDeleteModal" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteMarketArea" centered>
        <p>Deleting <span class="font-weight-bold">{{ market_area.name }}</span> will delete all of the users associated to it – are you sure you want to delete it?</p>
      </b-modal>
    </div>

    <loading-overlay v-else></loading-overlay>


  </div>
</template>

<script>
import { MarketAreaService } from '@/common/services/market_area.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import Regions from '../../common/components/regions.vue';

export default {
  name: 'MarketAreaShow',
  components: {
    Regions,
    LoadingOverlay,
  },
  data() {
    return {
      market_area: null,
      LoadingOverlay,
    };
  },
  created() {
    this.getMarketArea();
  },
  methods: {
    getMarketArea() {
      MarketAreaService.get(this.$route.params.id)
        .then((response) => {
          this.market_area = response.data;
        });
    },
    deleteMarketArea() {
      MarketAreaService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'MarketAreaIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted market area.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
  beforeRouteUpdate(to) {
    MarketAreaService.get(to.params.id)
      .then((response) => {
        this.market_area = response.data;
      });
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/market_areas";
</style>
