<template>
  <div v-if="regions" class="card">
    <h1>Market Area Regions</h1>
    <table class="card-table table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Region Code</th>
      </tr>
      </thead>
      <tbody>
      <region-row v-for="region in regions" :key="region.id" :region="region" :market_area_id="market_area_id" />
      </tbody>
    </table>
  </div>
</template>

<script>
import { RegionService } from '@/common/services/region.service';
import RegionRow from '@/common/components/regions-row.vue';
import { FilterService } from '@/common/services/filter.service';

export default {
  name: 'Regions',
  props: {
    market_area_id: Number,
  },
  components: {
    RegionRow,
  },
  data() {
    return {
      regions: [],
    };
  },
  created() {
    this.getRegions();
  },
  methods: {
    getRegions() {
      const params = FilterService.build({
        market_area_id: this.market_area_id,
        per_page: 1000,
      }, FilterService.defaultData());
      RegionService.query(params)
        .then((response) => {
          this.regions = response.data;
        });
    },
  },
};
</script>

<style scoped>

</style>
